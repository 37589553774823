<template>
  <div class="why-us" id="why_us">
    <div class="why-us-clipper"></div>
    <div class="why-us-content">
      <div class="why-us-title">
        <div class="head">{{$t('whyUs.head')}}</div>
        <div class="mini-title">{{$t('whyUs.title')}}</div>
      </div>
      <div class="why-us-desc">
        <div class="whyUs-item" v-for="(item, i) in $t('whyUs.reasons')" :key="i">
          <vue-lottie-player :key="keys[i]" name="anime" ref="lottie" :width="width" :height="height" :animationData="icons[i]" />
          <div class="mt-2">{{item}}</div>
        </div>
      </div>
    </div>
    <div v-observe-visibility="visibilityChanged"></div>
  </div>
</template>

<script>
import VueLottiePlayer from 'vue-lottie-player'
import maintain from '../../assets/animations/maintain.json'
import report from '../../assets/animations/report.json'
import road from '../../assets/animations/road.json'
import tech from '../../assets/animations/techno.json'
export default {
  name: 'WhyUs',
  components: { VueLottiePlayer },
  data () {
    return {
      icons: [maintain, tech, road, report],
      bool: true,
      keys: [0, 0, 0, 0],
      width: this.$store.state.width > 900 ? '60px' : '50px',
      height: this.$store.state.width > 900 ? '65px' : '55px'
    }
  },
  created () {
    this.$watch(() => {
      return this.$store.state.width
    }, val => {
      if (val <= 900) {
        this.width = '55px'
        this.height = '60px'
      }
    })
  },
  methods: {
    visibilityChanged (isVisible) {
      for (let i = 0; i < this.keys.length; i++) {
        this.keys[i]++
      }
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/sass/main";
.why-us{
  min-height: 380px;
  width: 100%;
  background: $--orange;
  position: relative;
}
.why-us-clipper {
  background: rgba(247,247,247, 0.24);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(82.843% 100%, 95.668% 0%, 100% 0%, 100% 100%, -8% 100%, 21.256% 0%, 92% 0%, 74% 100%);
  -webkit-clip-path: polygon(82.843% 100%, 95.668% 0%, 100% 0%, 100% 100%, -8% 100%, 21.256% 0%, 92% 0%, 74% 100%);
  z-index: 1;
}
.why-us-content{
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 0 3rem 0;
}
.why-us-title{
  color: $--black;
  font-family: lama-bold-expanded;
  width: max-content;
  margin: 0 auto;
}
.head{
  word-spacing: 4px;
  text-align: center !important;
  font-size: 50px;
  font-family: inherit;
  text-align: initial;
}
.mini-title{
  word-spacing: 3px;
  text-align: center;
  font-family: inherit;
  font-size: 30px;
}
.why-us-desc{
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  align-items: center;
}
.whyUs-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}
.whyUs-item > div{
  font-family: lama-bold-expanded;
  font-size: 16.5px;
}
@media (max-width: 900px) {
  .head{
    font-size: 45px;
  }
  .mini-title{
    font-size: 25px;
  }
  .why-us-desc{
    width: 95%;
  }
  .why-us-content{
    padding: 2.5rem 0 2.5rem 0;
  }
  .whyUs-item > div{
    font-family: lama-bold-expanded;
    font-size: 15.5px;
  }
  .img{
    width: 50px;
    height: 55px
  }
}
@media (max-width: 750px) {
  .why-us-clipper {
    clip-path: polygon(60.843% 100%, 91% 0%, 100% 0%, 100% 100%, -15% 100%, 56.256% 0%, 83.5% 0%, 37.595% 100%);
    -webkit-clip-path: polygon(60.843% 100%, 91% 0%, 100% 0%, 100% 100%, -15% 100%, 56.256% 0%, 83.5% 0%, 37.595% 100%) !important;
  }
}
@media (max-width: 700px) {
  #why_us {
    min-height: 300px;
  }
  .head{
    font-size: 34px;
  }
  .mini-title{
    font-size: 15px;
  }
  .img{
    width: 45px;
    height: 50px
  }
  .whyUs-item > div{
    font-size: 13px;
  }
  .why-us-desc{
    width: 90%;
  }
}
@media (max-width: 500px) {
  .head{
    font-size: 22px;
  }
}
@media (max-width: 525px) {
  .why-us-desc{
    width: 95%;
  }
  .img{
    width: 40px;
    height: 45px
  }
  .whyUs-item > div{
    font-size: 11px;
  }
}
@media (max-width: 480px) {
  .why-us-desc{
    width: 88%;
  }
  #why_us{
    min-height: 400px
  }
  .why-us-desc{
    flex-wrap: wrap;
    margin-top: 20px;
    min-height: 200px
  }
  .whyUs-item{
    width: 50%;
    margin: 20px 0 0 0
  }
  .img{
    width: 40px;
    height: 45px
  }
  .whyUs-item > div{
    font-size: 11px;
  }
}
</style>
